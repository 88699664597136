var general = (function() {
  'use strict';

  var config = {
    selectors: {
      owl_carousel: $('.fera-slider'),
      owl_thumbs: $('.fera-slider-thumbs'),
      menu: $('.menu-mobile'),
      logo: $('.header-logo'),
      nav: $('.header-nav'),
      body: $('body'),
      target: $('a[data-target]'),
      accordion: $('.accordion'),
      accordion_content: $(".accordion-content"),
      number_container: $('.number-container')
    },

    classes: {
      active: 'active'
    },

    data: {
      home: {
        loop: false,
        items: 1,
        nav: true,
        autoplay: true,
        loop: true,
        slideTransition: 'ease-in-out',
        smartSpeed: 1000
      },
      carousel: {
        loop: false,
        items: 3,
        nav: true,
        dots: false,
        responsive:{
          0:{
            items:1,
            nav:  false,
            dots: true
          },
          600:{
            items:3,
            nav:  true,
            dots: false
          }
        }
      },
      depoimentos: {
        loop: true,
        items: 3,
        nav: false,
        dots: true,
        center: true,
        autoWidth:true,
        stagePadding: 250
      },
      singleParkingGallery: {
        items: 1,
        dots: true,
        autoplay: 5000,
        autoPauseOnHover: true
      },
      product: {
        loop: false,
        items: 1,
        nav: true
      },
      thumbs: {
        loop:false,
        nav:false,
        center:true,
        responsive:{
          0:{
            items:3
          },
          600:{
            items:4
          },
          1000:{
            items:6
          }
        }
      }
    }
  }

  function init() {
    carousel();
    config.selectors.menu.on('click', menu);
    submenu();
    config.selectors.target.on('click', function(e){
      e.preventDefault();
      scrollToTarget($(this).data('target'));
    });
    // fixedFooter();
    // scrollToHash();
    // customInputNumber();
    //$(window).scroll(fixedFooter);

    countdown();
    accordion();

  }

  function countdown(){
    "use strict";

    $('.clock').countdown('2019/11/30', function(event) {
      $(this).html(event.strftime('<div class="dias"><div>%D</div> <span>dias</span></div> <div class="horas"><div>%H</div> <span>horas</span></div> <div class="minutos"><div>%M</div> <span>minutos</span></div> <div class="segundos"><div>%S</div> <span>segundos</span></div>'));
    });
  }

  function menu(){
    $(this).toggleClass('-opened');
    config.selectors.logo.toggleClass('-opened');
    config.selectors.body.toggleClass('-o-hidden');
    config.selectors.nav.toggleClass('-opened');
  }

  function submenu() {
      var $selector, closeClass, openClass;
      if (window.innerWidth >= 990) {
        $selector = $('.submenu');
        openClass = '-opened';
        closeClass = '-closed';
        return $('.menu-item-has-children').hover((function() {
          $(this).addClass('hover');
          $('ul:first', this).css('display', 'block');
        }), function() {
          $(this).removeClass('hover');
          $('ul:first', this).css('display', 'none');
        });
      }
    }

  function carousel() {
    config.selectors.owl_carousel.each(function() {
      $(this).owlCarousel(config.data[$(this).data('carousel')]).on('changed.owl.carousel', function (e) {
       config.selectors.owl_thumbs.trigger('to.owl.carousel', [e.item.index, 500, true]);
      });;
    });

    config.selectors.owl_thumbs.each(function() {
      $(this).owlCarousel(config.data[$(this).data('carousel')]).on('click', '.owl-item', function () {
         config.selectors.owl_carousel.trigger('to.owl.carousel', [$(this).index(), 500, true]);
        }).on('changed.owl.carousel', function (e) {
         config.selectors.owl_carousel.trigger('to.owl.carousel', [e.item.index, 500, true]);
      });;
    });
  }

  function accordion(){
    config.selectors.accordion.find('.accordion-toggle').click(function(){
        console.log('entrou');
      config.selectors.accordion.find('li').removeClass('-active');
      $(this).next().slideToggle('fast');
      config.selectors.accordion_content.not($(this).next()).slideUp('fast');
      $(this).parent().toggleClass('-active');
    });
  }

  function scrollToTarget(id){
      id = id.replace("link", "");
      $('html,body').animate({
        scrollTop: $("#"+id).offset().top},
      'slow');
  }

  function scrollToHash(){
    if(window.location.hash != ""){
      var elem = $('#' + window.location.hash.slice(1));
      if(elem) {
           $('html,body').animate({
            scrollTop: elem.offset().top -100},
          'slow');
      }
    }
  }

  function fixedFooter(){
    var scroll = $(window).scrollTop();
    var menuOffset = $('.main-footer').offset().top;
    if (scroll >= menuOffset - 800) {
        $(".info-footer").addClass('-static');
    }
    else{
        $(".info-footer").removeClass('-static');
    }
  }

  function customInputNumber(){
    config.selectors.number_container.each(function() {
      var el = $(this),
        input = el.find('input[type="number"]'),
        plus = el.find('.plus'),
        minus = el.find('.minus'),
        min = input.attr('min'),
        max = input.attr('max');

      plus.click(function() {
        var actualVal = parseFloat(input.val());
        if (actualVal >= max) {
          var newVal = actualVal;
        } else {
          var newVal = actualVal + 1;
        }
        el.find("input").val(newVal);
        el.find("input").trigger("change");
      });

      minus.click(function() {
        var actualVal = parseFloat(input.val());
        if (actualVal <= min) {
          var newVal = actualVal;
        } else {
          var newVal = actualVal - 1;
        }
        el.find("input").val(newVal);
        el.find("input").trigger("change");
      });

    });
  }

  return {
    init: init
  };
}());

$(document).ready(function() {
  general.init();
});
